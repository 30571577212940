/*
 * @Author: bug killer: liuyh
 * @Date: 2024年1月4日 18点11分
 * @LastEditors: bug killer: liuyh
 * @LastEditTime: 2024年1月4日 18点11分
 * @Description: file content
 */
import Vue from 'vue'
import App from './App'

Vue.config.productionTip = false
Vue.prototype.$config = window.VDrag_CONFIG
Vue.prototype.APPINFO = window.APPINFO

new Vue({
  render: h => h(App),
}).$mount('#app')