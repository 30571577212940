<template>
  <div>
    <div class="project-list-title">精品案例</div>
    <div class="project-list">
      <div class="card-div" v-for="(item, index) in projectList" :key="index">
        <ProjectCard :cardItem="item" />
      </div>
    </div>
  </div>
</template>

<script>
import ProjectCard from './projectCard'
export default {
  name: 'ProjectList',
  components: { ProjectCard },
  data() {
    return {
      projectList: [
        {
          title: '化工园区安全风险智能化管控平台',
          imagUrl: require('../assets/image/example/pro1.png'),
          desc: '以园区安全基础管理为需求背景，便捷集成开源的后台管理系统“若依”，完成园区后台业务管理系统的快速搭建，同时可灵活配置用户角色及权限，助力化工园区安全高质量发展。',
          jumpTo: '/geovis/projectTemplate/化工园区门户@vDrag/pages/平台门户星图云/index.html#/'
        },
        {
          title: '智能工区管理平台',
          imagUrl: require('../assets/image/example/pro2.png'),
          desc: '构建多终端（PC/手机）的企业智能化工位管理平台，通过图形化的方式直观呈现工位使用情况，管理人员可上传、编辑工位地图，审批工位预约申请；员工可快捷地完成工位预约申请，并基于工位地图实现快速找人。',
          jumpTo: '/geovis/projectTemplate/智能工区管理系统@工位管理系统/pages/seatSys/index.html#/'
        },
        {
          title: '天空地防护巡一体化系统',
          imagUrl: require('../assets/image/example/pro3.png'),
          desc: '以管网安全运营为目标，采用卫星遥感、无人机巡检、智能视频等多手段布防，搭建“天空地”防护巡三位一体风险监控网，全面消除管道风险管控的“时间+空间”盲区，构建综合研判与动态联动处置机制。',
          jumpTo: '/geovis/projectTemplate/CQ演示项目@CQ/pages/系统首页/index.html#/'
        },
        {
          title: '智能车辆运营管理平台',
          imagUrl: require('../assets/image/example/pro4.png'),
          desc: '融合多源异构时空数据，依托无人巡检车辆位置、车辆基本状态、视频等实时动态数据，综合运用移动通讯、大数据、云计算等技术，形成综合性集成化的在线智巡平台，实现清晰看车、高效管理、智慧用车的目标。',
          jumpTo: '/geovis/projectTemplate/小巡运营管理平台@PIM支撑/pages/小巡运营管理平台/index.html#/'
        },
        {
          title: '管线高后果区识别及风险评价',
          imagUrl: require('../assets/image/example/pro5.png'),
          desc: '基于管线完整性数据库和地物要素识别结果，依据管线高后果区识别规范，形成风险评估体系，提供专项风险识别评估能力，辅助前后方协调、物资设备调度，为管线资产的完整、准确、经济、安全保驾护航。',
          jumpTo: '/geovis/projectTemplate/高后果@A演示团队/pages/hca路由页/index.html#/'
        },
        {
          title: '金融风控立体化监管服务平台',
          imagUrl: require('../assets/image/example/pro6.png'),
          desc: '面向银行、租赁、经济研究等金融机构，建立智能风控分析模型，解决金融资产在哪里、怎么样、有无问题等监管痛点，提升金融机构智能风控能力，杜绝欺诈、降低投资不良率。',
          jumpTo: 'https://devtest.geovisearth.com/publish/cdef81092785da419632/6ded851b017412b9da23/pages/集成页/index.html'
        }
      ],
    }
  }
}
</script>

<style scoped>
.project-list-title {
  font-size: 24px;
  font-weight: 700;
  letter-spacing: 0px;
  line-height: 34.75px;
  color: rgba(250, 250, 250, 1);
  text-align: center;
  margin: 80px 0 64px 0;
}
  .project-list {
    position: relative;
    text-align: center;
    width: 1350px;
    margin: 0 auto;
  }
  .card-div {
    display: inline-block;
    padding: 15px;
  }
</style>