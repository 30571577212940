<template>
  <div class="banner">
    <div class="banner-content">
      <span class="title1"
        ><span class="vDrag">{{ APPINFO.message }}</span></span
      >
      <span class="title2" style="">{{ APPINFO.message1 }}</span>
      <span @click="toAdmin()" class="tovDrag" >立即体验</span>
      <span class="tag" style="">{{ keys }}</span>
    </div>
    <img class="arrowDown" src="../assets/image/icon_up.png" alt="" />
  </div>
</template>

<script>
export default {
  name: "topBanner",
  data() {
    return {};
  },
  mounted() {},
  computed: {
    keys() {
      return this.APPINFO.keywords.join(" | ");
    },
  },
  methods: {
    toAdmin() {
      if (
        document.body.clientWidth < 800 ||
        navigator.userAgent.match(
          /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
        )
      ) {
        alert('您好，检测到您当前的设备为移动端设备，为了您能更好的使用vDrag，建议您使用PC端Chrome浏览器进行访问。')
        return;
      }
      window.open("./admin/", '_blank');
    },
  },
};
</script>

<style scoped>
* {
  outline: none;
  margin: 0;
  padding: 0;
  text-decoration: none;
}
.banner {
  width: 100vw;
  height: 100vh;
  background-image: url("../assets/image/banner.png");
  background-size: 100% 100%;
}
.banner-content {
  height: 95%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #fff;
  position: absolute;
  top: 20%;
  left: 50%;
  transform: translateX(-50%);
}
.title1 {
  width: 100%;
  text-align: center;
  font-size: 60px;
  font-family: 微软雅黑;
}
.vDrag {
  letter-spacing: 1px;
  margin-right: 10px;
}
.title2 {
  width: 100%;
  text-align: center;
  font-size: 50px;
  margin-top: 50px;
}

.tovDrag {
  width: 227px;
  height: 67px;
  line-height: 67px;
  border-radius: 36px;
  text-indent: 65px;
  color: #fff;
  font-size: 24px;
  cursor: pointer;
  outline: none;
  display: inline-block;
  margin-top: 56px;
  background-size: 300% 100%;
  transition: all 0.4s ease-in-out;
  background-image: linear-gradient(
    to right,
    #25aae1,
    #4481eb,
    #04befe,
    #3f86ed
  );
  box-shadow: 0 4px 15px 0 rgba(65, 132, 234, 0.75);
}

.tovDrag:hover {
  background-position: 100% 0;
}

.tag {
  font-size: 34px;
  letter-spacing: 6px;
  margin-top: 120px;
}
.arrowDown {
  width: 20px;
  position: absolute;
  left: 49.8%;
  transform: translate(-50%, 0);
  bottom: 5%;
  animation: jump 0.7s linear infinite;
}
@keyframes jump {
  0% {
    bottom: calc(5%);
  }
  50% {
    bottom: calc(5% - 15px);
  }
  100% {
    bottom: calc(5%);
  }
}
</style>
