<template>
  <div class="project-card">
    <img class="project-img" :src="cardItem.imagUrl" alt="" />
    <div class="hover-div">
      <div class="detail-button" @click="goTo(cardItem.jumpTo)">
        查看详情
      </div>
    </div>
    <div class="title-text">
      <div class="title">{{ cardItem.title }}</div>
      <div class="des">{{ cardItem.desc }}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ProjectCard',
  data() {
    return {};
  },
  props: {
    cardItem: {
      type: Object,
    },
  },
  mounted() {},

  methods: {
    goTo(url) {
      window.open(url);
    }
  },
};
</script>

<style scoped>
.project-card {
  width: 420px;
  height: 230px;
  position: relative;
  overflow: hidden;
}
.project-img {
  width: 100%;
  height: 100%;
  transition: all .3s;
}
.title-text {
  position: absolute;
  bottom: 0px;
  height: 50%;
  padding: 0 15px 10px;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 100%);
  width: 100%;
}
.hover-div {
  width: 100%;
  height: 100%;
  position: absolute;
  background-color: rgba(0, 0, 0, 0.5);
  visibility: hidden;
  top: 0;
}
.detail-button {
  text-align: center;
  display: inline-block;
  line-height: 36px;
  color: #fff;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  margin: 0 auto;
  width: 120px;
  height: 36px;
  opacity: 1;
  border-radius: 4px;
  cursor: pointer;
  z-index: 9;
  background: linear-gradient(221.69deg, rgba(87, 187, 255, 1) 0%, rgba(44, 134, 255, 1) 100%);
}
.card-div:hover .project-img {
  transform: scale(1.2)
}
.card-div:hover .title,
.card-div:hover .des {
  visibility: hidden;
}
.card-div:hover .hover-div {
  visibility: visible;
  transition: all .3s;
}
.title {
  font-size: 20px;
    font-weight: 500;
    letter-spacing: 0px;
    line-height: 34.75px;
    color: rgba(255, 255, 255, 1);
    text-align: left;
    top: 30px;
    position: absolute;
    width: 100%;
}
.des {
  opacity: 0.7;
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 0px;
  line-height: 17.38px;
  color: rgba(255, 255, 255, 1);
  text-align: justify;
  top: 63px;
  position: absolute;
  width: calc(100% - 59px);
 }
</style>