<template>
  <div class="guide">
    <div class="guide-content">
      <div class="guide-title">
        <span>知识库</span>
      </div>
      <div class="line"></div>
      <div class="innerBox">
        <div class="boxLeft">
          <div
            data-boxItem="1"
            :class="{
              boxLeftItem1: true,
            }"
            @mouseenter="boxLeftEnter($event)"
            @mouseleave="boxLeftLeave($event)"
          >
            <div class="lanDesc">
              <div class="lanTitle">产品介绍</div>
              <div class="lanContent">
                <a
                  href='https://www.yuque.com/hututubuhutu-x8mb5/xcetyh/de7x6m'
                  target="_blank"
                  data-item="1-1"
                  @mouseenter="enter($event)"
                  @mouseleave="leave($event)"
                  :class="{ current: itemFlag === '1-1' }"
                  >产品简介</a
                >
                <a
                  href='https://www.yuque.com/hututubuhutu-x8mb5/xcetyh/cyx8xq'
                  target="_blank"
                  data-item="1-2"
                  @mouseenter="enter($event)"
                  @mouseleave="leave($event)"
                  :class="{ current: itemFlag === '1-2' }"
                  >产品能力</a
                >
                <a
                  href='https://www.yuque.com/hututubuhutu-x8mb5/xcetyh/xqdirq'
                  target="_blank"
                  data-item="1-3"
                  @mouseenter="enter($event)"
                  @mouseleave="leave($event)"
                  :class="{ current: itemFlag === '1-3' }"
                  >产品优势</a
                >
                <a
                  href='https://www.yuque.com/hututubuhutu-x8mb5/xcetyh/we6zg8'
                  target="_blank"
                  data-item="1-4"
                  @mouseenter="enter($event)"
                  @mouseleave="leave($event)"
                  :class="{ current: itemFlag === '1-4' }"
                  >应用领域</a
                >
                <a
                  href='https://www.yuque.com/hututubuhutu-x8mb5/xcetyh/dw5egk'
                  target="_blank"
                  data-item="1-5"
                  @mouseenter="enter($event)"
                  @mouseleave="leave($event)"
                  :class="{ current: itemFlag === '1-5' }"
                  >运行环境</a
                >
                <a
                  href='https://www.yuque.com/hututubuhutu-x8mb5/xcetyh/hkdb94'
                  target="_blank"
                  data-item="1-6"
                  @mouseenter="enter($event)"
                  @mouseleave="leave($event)"
                  :class="{ current: itemFlag === '1-6' }"
                  >更新记录</a>
                <a
                  href='https://www.yuque.com/hututubuhutu-x8mb5/xcetyh/ulxc17'
                  target="_blank"
                  data-item="1-7"
                  @mouseenter="enter($event)"
                  @mouseleave="leave($event)"
                  :class="{ current: itemFlag === '1-7' }"
                  >私有化部署</a
                >

              </div>
            </div>
            <img
            v-show="this.boxItemFlag !== '1'"
              class="boxLeftItem1Img"
              src="../assets/image/组2213.png"
            />
            <img
            v-show="this.boxItemFlag === '1'"
              class="boxLeftItem1Img"
              src="../assets/image/组2204.png"
            />
            <img v-show="this.boxItemFlag === '1'" :class="{
              leftBackImgStyle:true,
              hoverStyle: this.boxItemFlag === '1'
            }" src="../assets/image/组2180.png" alt="">
          </div>
          <div
            data-boxItem="3"
            :class="{
              boxLeftItem3: true,
            }"
            @mouseenter="boxLeftEnter($event)"
            @mouseleave="boxLeftLeave($event)"
          >
            <div class="lanDesc">
              <div class="lanTitle">组件开发</div>
              <div class="lanContent">
                <a
                  href='https://www.yuque.com/hututubuhutu-x8mb5/xctmls/gnegbo'
                  target="_blank"
                  data-item="2-1"
                  @mouseenter="enter($event)"
                  @mouseleave="leave($event)"
                  :class="{ current: itemFlag === '2-1' }"
                  >环境配置及安装</a
                >
                <a
                  href='https://www.yuque.com/hututubuhutu-x8mb5/xctmls/zkivnb'
                  target="_blank"
                  data-item="2-2"
                  @mouseenter="enter($event)"
                  @mouseleave="leave($event)"
                  :class="{ current: itemFlag === '2-2' }"
                  >使用及开发组件</a
                >
                <a
                  href='https://www.yuque.com/hututubuhutu-x8mb5/xctmls/xihy5d'
                  target="_blank"
                  data-item="2-3"
                  @mouseenter="enter($event)"
                  @mouseleave="leave($event)"
                  :class="{ current: itemFlag === '2-3' }"
                  >常见问题</a
                >
              </div>
            </div>
            <img
            v-show="this.boxItemFlag !== '3'"
              class="boxLeftItem3Img"
              src="../assets/image/组2208.png"
            />
            <img
            v-show="this.boxItemFlag === '3'"
              class="boxLeftItem3Img"
              src="../assets/image/组2209.png"
            />
            <img v-show="this.boxItemFlag === '3'" :class="{
              leftBackImgStyle:true,
              hoverStyle: this.boxItemFlag === '3'

            }"  src="../assets/image/组2180.png" style="height:251px" alt="">
          </div>
        </div>
        <div class="boxLeft">
          <div
            data-boxItem="2"
            :class="{
              boxLeftItem2: true,
            }"
            @mouseenter="boxLeftEnter($event)"
            @mouseleave="boxLeftLeave($event)"
          >
            <div class="lanDesc">
              <div class="lanTitle">基础操作指南</div>
              <ul class="lanContent">
                <a
                  href='https://www.yuque.com/hututubuhutu-x8mb5/xcetyh/qggdgy'
                  target="_blank"
                  data-item="3-7"
                  @mouseenter="enter($event)"
                  @mouseleave="leave($event)"
                  :class="{ current: itemFlag === '3-7' }"
                  >快速创建应用</a
                >
                <a
                  href='https://www.yuque.com/hututubuhutu-x8mb5/xcetyh/yb2xr3'
                  target="_blank"
                  data-item="3-8"
                  @mouseenter="enter($event)"
                  @mouseleave="leave($event)"
                  :class="{ current: itemFlag === '3-8' }"
                  >基本概念</a
                >
                <a
                href='https://www.yuque.com/hututubuhutu-x8mb5/xcetyh/sgazdu'
                  target="_blank"
                  data-item="3-1"
                  @mouseenter="enter($event)"
                  @mouseleave="leave($event)"
                  :class="{ current: itemFlag === '3-1' }"
                  >项目管理</a
                >
                <a

                  href='https://www.yuque.com/hututubuhutu-x8mb5/xcetyh/ymtlsc'
                  target="_blank"
                  data-item="3-2"
                  @mouseenter="enter($event)"
                  @mouseleave="leave($event)"
                  :class="{ current: itemFlag === '3-2' }"
                  >资源管理</a
                >
                <a
                  href='https://www.yuque.com/hututubuhutu-x8mb5/xcetyh/hy2o4q'
                  target="_blank"
                  data-item="3-4"
                  @mouseenter="enter($event)"
                  @mouseleave="leave($event)"
                  :class="{ current: itemFlag === '3-4' }"
                  >编辑器</a
                >
                <a
                  href='https://www.yuque.com/hututubuhutu-x8mb5/xcetyh/mbof04'
                  target="_blank"
                  data-item="3-5"
                  @mouseenter="enter($event)"
                  @mouseleave="leave($event)"
                  :class="{ current: itemFlag === '3-5' }"
                  >组件管理</a
                >
                <a
                  href='https://www.yuque.com/hututubuhutu-x8mb5/xcetyh/zcf9dl'
                  target="_blank"
                  data-item="3-6"
                  @mouseenter="enter($event)"
                  @mouseleave="leave($event)"
                  :class="{ current: itemFlag === '3-6' }"
                  >资产库管理</a
                >
              </ul>
            </div>
            <img
            v-show="this.boxItemFlag !== '2'"
              class="boxLeftItem2Img2"
              src="../assets/image/组2206.png"
            />
            <img
            v-show="this.boxItemFlag === '2'"
              class="boxLeftItem2Img2"
              src="../assets/image/组2210.png"
            />
            <img v-show="this.boxItemFlag === '2'" :class="{
              leftBackImgStyle:true,
              hoverStyle: this.boxItemFlag === '2'
            }"  src="../assets/image/组2180.png" alt="">
          </div>
          <div
            data-boxItem="4"
            :class="{
              boxLeftItem4: true,
            }"
            @mouseenter="boxLeftEnter($event)"
            @mouseleave="boxLeftLeave($event)"
          >
          <div class="lanDesc">
              <div class="lanTitle">进阶指南</div>
              <div class="lanContent">
                <a
                  href='https://www.yuque.com/hututubuhutu-x8mb5/xcetyh/tq7tvc'
                  target="_blank"
                  data-item="4-1"
                  @mouseenter="enter($event)"
                  @mouseleave="leave($event)"
                  :class="{ current: itemFlag === '4-1' }"
                  >高级样式</a
                >
                <a
                  href='https://www.yuque.com/hututubuhutu-x8mb5/xcetyh/sbzp1b'
                  target="_blank"
                  data-item="4-2"
                  @mouseenter="enter($event)"
                  @mouseleave="leave($event)"
                  :class="{ current: itemFlag === '4-2' }"
                  >组件交互</a
                >
                <a
                  href='https://www.yuque.com/hututubuhutu-x8mb5/xcetyh/ywk2ep'
                  target="_blank"
                  data-item="4-3"
                  @mouseenter="enter($event)"
                  @mouseleave="leave($event)"
                  :class="{ current: itemFlag === '4-3' }"
                  >数据过滤器</a
                >
                <a
                  href='https://www.yuque.com/hututubuhutu-x8mb5/xcetyh/prgcqf'
                  target="_blank"
                  data-item="4-4"
                  @mouseenter="enter($event)"
                  @mouseleave="leave($event)"
                  :class="{ current: itemFlag === '4-4' }"
                  >自定义组件</a
                >
                <a
                  href='https://www.yuque.com/hututubuhutu-x8mb5/xcetyh/ysnytn'
                  target="_blank"
                  data-item="4-5"
                  @mouseenter="enter($event)"
                  @mouseleave="leave($event)"
                  :class="{ current: itemFlag === '4-5' }"
                  >数据源</a
                >
              </div>
            </div>
            <img
            v-show="this.boxItemFlag !== '4'"
              class="boxRightItem1Img2"
              src="../assets/image/组2207.png"
            />
            <img
            v-show="this.boxItemFlag === '4'"
              class="boxRightItem1Img2"
              src="../assets/image/组2211.png"
            />
            <img v-show="this.boxItemFlag === '4'"
            :class="{
              leftBackImgStyle:true,
              hoverStyle: this.boxItemFlag === '4'
            }"
            src="../assets/image/组2180.png" alt="">
          </div>
        </div>

        <div class="boxRight">
          <div
            data-boxrightitem="1"
            :class="{
              boxRightItem:true,
              boxRightItem1: true,
            }"
            @mouseenter="boxRightEnter($event)"
            @mouseleave="boxRightLeave($event)"
          >
            <div class="lanDesc">
              <div class="lanTitle">常见问题</div>
              <div class="lanContent">
                <a
                  href='https://www.yuque.com/hututubuhutu-x8mb5/xcetyh/ae5tkt'
                  target="_blank"
                  data-item="5-1"
                  @mouseenter="enter($event)"
                  @mouseleave="leave($event)"
                  :class="{ currentBlack: itemFlag === '5-1' }"
                  >页面自适应问题</a
                >
                <a
                  href='https://www.yuque.com/hututubuhutu-x8mb5/xcetyh/kelhbn'
                  target="_blank"
                  data-item="5-2"
                  @mouseenter="enter($event)"
                  @mouseleave="leave($event)"
                  :class="{ currentBlack: itemFlag === '5-2' }"
                  >无界面组件的操作</a
                >
                <a
                  href='https://www.yuque.com/hututubuhutu-x8mb5/xcetyh/nmbdpb'
                  target="_blank"
                  data-item="5-3"
                  @mouseenter="enter($event)"
                  @mouseleave="leave($event)"
                  :class="{ currentBlack: itemFlag === '5-3' }"
                  >引入第三方库</a
                >
                <a
                  href='https://www.yuque.com/hututubuhutu-x8mb5/xcetyh/li4l6n'
                  target="_blank"
                  data-item="5-4"
                  @mouseenter="enter($event)"
                  @mouseleave="leave($event)"
                  :class="{ currentBlack: itemFlag === '5-4' }"
                  >表格如何增加按钮</a
                >
                <a
                  href='https://www.yuque.com/hututubuhutu-x8mb5/xcetyh/eqlyap'
                  target="_blank"
                  data-item="5-5"
                  @mouseenter="enter($event)"
                  @mouseleave="leave($event)"
                  :class="{ currentBlack: itemFlag === '5-5' }"
                  >多页面项目开发</a
                >
              </div>
            </div>

            <img
            v-show="this.boxRightItemFlag !== '1'"
              class="boxRightItem1Img2"
              src="../assets/image/组2203.png"
            />
            <img
            v-show="this.boxRightItemFlag === '1'"
              class="boxRightItem1Img2"
              src="../assets/image/组2212.png"
            />

            <div class="backImg" v-show="boxRightItemFlag !== '1'">
              <img
                class="boxRightItem1Img1"
                src="../assets/image/图2.png"
                alt=""
              />

            </div>

            <div
              class="topImg"
              v-show="boxRightItemFlag === '1'"
              :class="{
                topImg: true,
                specialHoverStyle: this.boxRightItemFlag === '1',
              }"
            >
              <img
                class="topBackgroundImg"
                src="../assets/image/图4.png"
                alt=""
              />
              <a href="https://www.yuque.com/hututubuhutu-x8mb5/xcetyh/de7x6m" target="_blank">
                <div
                :class="{
                knowMore: true,
                downStyle: this.boxRightItemFlag === '1',
              }"
              >
                <span class="text">了解更多</span>
                <img
                  class="boxRightItem2Img1"
                  src="../assets/image/查看更多.png"
                  alt=""
                />
              </div>
              </a>
            </div>

          </div>

          <div
            data-boxrightitem="2"
            :class="{
              boxRightItem:true,
              boxRightItem2: true,
            }"
            @mouseenter="boxRightEnter($event)"
            @mouseleave="boxRightLeave($event)"
          >
            <div class="lanDesc">
              <div class="lanTitle">组件指南</div>
              <div class="lanContent">
                <a
                  href='https://www.yuque.com/hututubuhutu-x8mb5/gqz85i/tdok0g'
                  target="_blank"
                  data-item="6-1"
                  @mouseenter="enter($event)"
                  @mouseleave="leave($event)"
                  :class="{ currentBlack: itemFlag === '6-1' }"
                  >界面组件</a
                >
                <a
                  href='https://www.yuque.com/hututubuhutu-x8mb5/gqz85i/hgk04k'
                  target="_blank"
                  data-item="6-2"
                  @mouseenter="enter($event)"
                  @mouseleave="leave($event)"
                  :class="{ currentBlack: itemFlag === '6-2' }"
                  >图表组件</a
                >
              </div>
            </div>

            <img
            v-show="this.boxRightItemFlag !== '2'"
              class="boxRightItem1Img2"
              src="../assets/image/组2215.png"
            />
            <img
            v-show="this.boxRightItemFlag === '2'"
              class="boxRightItem1Img2"
              src="../assets/image/组2214.png"
            />

            <div
              class="topImg"
              v-show="boxRightItemFlag === '2'"
              :class="{
                topImg: true,
                specialHoverStyle: this.boxRightItemFlag === '2',
              }"
            >
              <img
                class="topBackgroundImg"
                src="../assets/image/图4.png"
                alt=""
              />
              <a href='https://www.yuque.com/hututubuhutu-x8mb5/gqz85i/tdok0g' target="_blank">
                <div
               :class="{
                  knowMore: true,
                  downStyle: this.boxRightItemFlag === '2',
               }"
              >
                <span class="text">了解更多</span>
                <img
                  class="boxRightItem2Img1"
                  src="../assets/image/查看更多.png"
                  alt=""
                />
              </div>
              </a>
            </div>
          </div>
          </div>
        </div>
      </div>
    </div>
</template>

<script>
export default {
  name: 'myGuide',
  data () {
    return {
      itemFlag: '1',
      boxItemFlag: '1',
      boxRightItemFlag: '2',
      // address: window.VDrag_CONFIG.SEVEN_PATH
    }
  },
  mounted () {
  },
  methods: {
    enter (e) {
      this.itemFlag = e.target.dataset.item
    },
    leave () {
      this.itemFlag = '1'
    },
    boxLeftEnter (e) {
      // console.log(e);
      this.boxItemFlag = e.target.dataset.boxitem
    },
    boxLeftLeave () {
      this.boxItemFlag = '1'
    },
    boxRightEnter (e) {
      this.boxRightItemFlag = e.target.dataset.boxrightitem
    },
    boxRightLeave () {
      this.boxRightItemFlag = '2'
    }
  }
}
</script>

<style scoped>
* {
  margin: 0;
  padding: 0;
}
a {
  text-decoration: none;
  display: block;
  list-style: none;
  text-align: left;
  width: 112px;
  color: inherit;
}
.guide {
  width: 100%;
  background-image: url("../assets/image/guide.png");
  background-size: 100% 100%;
  position: relative;
  padding: 80px 0;
  }

.guide-title {
  width: 96px;
  height: 31px;
  font-family: Microsoft YaHei;
  font-size: 24px;
  font-weight: normal;
  font-stretch: normal;
  line-height: 31px;
  letter-spacing: 0px;
  color: #fafafa;
  text-align: left;
}

.line {
  width: 72px;
  height: 0px;
  border: solid 2px #ffffff;
  margin: 28px 0 20px 0;
}

.guide-content {
  width: fit-content;
  height: fit-content;
  margin: 0 auto;
}

/* 内容样式 */
.innerBox {
  height: 505px;
  display: flex;
  justify-content: center;
  background-color: #2a2c3c;
}
.innerBox .boxLeft {
  height: 100%;
  width: 320px;
  border-left: solid 1px #3d3f4d;
  display:flex;
  flex-wrap: wrap;
}
.boxLeftItem1,
.boxLeftItem2,
.boxLeftItem3,
.boxLeftItem4 {
  position: relative;
  float: left;
  box-sizing: border-box;
}
.boxLeftItem1 {
  width: 100%;
  height: 254px;
  border-bottom: solid 1px #3d3f4d;
}
.hoverStyle {
  animation: change3 0.3s ease-in forwards;
}
.leftBackImgStyle {
  width: 100%;
  height: 100%;
}
.boxLeftItem1Img {
  position: absolute;
  right: 30px;
  bottom: 20px;
  z-index: 106;
}
.lanDesc {
  color: white;
  width: 100%;
  height: 200px;
  position: absolute;
  z-index: 105;
  padding-top: 20px;
  padding-left: 46px;
  box-sizing: border-box;
}
.lanTitle {
  width:200px;
  height: 24px;
  font-family: Source Han Sans CN;
  font-size: 24px !important;
  font-weight: normal;
  font-stretch: normal;
  line-height: 41px;
  letter-spacing: 0px;
  color: #fafafa;
  text-align: left;
  cursor: default;
}
.lanContent {
  width: 100%;
  height: 86px;
  font-family: Source Han Sans CN;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  line-height: 24px;
  letter-spacing: 0px;
  color: #fafafa !important;
  position: absolute;
  left: 46px;
  top: 66px;
  cursor: pointer;
}
.lanContent a{
  width: 100%!important;
}
.lanContent .current {
  color: #409eff;
}
.lanContent .currentBlack {
  color: #2a2c3c;
}

.boxLeftItem2 {
  width: 100%;
  height: 254px;
  border-bottom: solid 1px #3d3f4d;
  background: url('../assets/image/图3.png');
  background-repeat: no-repeat;
  background-position: 123px 110px;
}
.boxLeftItem2Img1 {
  width: 172px;
  height: 118px;
  opacity: 0.68;
  position: absolute;
  right: 30px;
  bottom: 20px;
}
.boxLeftItem2Img2 {
  width: 28px;
  height: 28px;
  position: absolute;
  right: 30px;
  bottom: 20px;
  z-index: 106;
}

.boxLeftItem3 {
  width: 100%;
  height: 250px;
}
.boxLeftItem3Img {
  width: 28px;
  height: 28px;
  position: absolute;
  right: 30px;
  bottom: 20px;
  z-index: 106;
}

.boxLeftItem4 {
  width: 100%;
  height: 250px;
}

.boxLeftItem4Img {
  width: 298px;
  height: 139px;
  position: absolute;
  right: 30px;
  bottom: 20px;
}

.innerBox .boxRight {
  width: 699px;
  height: 100%;
  border-left: solid 1px #3d3f4d;
  display: flex;
}

.boxRightItem1,
.boxRightItem2 {
  width: 356px;
  height: 505px;
  float: left;
  position: relative;
}

.specialHoverStyle {
  animation: change1 0.4s ease-in forwards;
}
.boxRightItem1Img1 {
  width: 263px;
  height: 216px;
  position: absolute;
  right: 30px;
  bottom: 20px;
}
.boxRightItem1Img2 {
  width: 28px;
  height: 28px;
  position: absolute;
  right: 30px;
  bottom: 20px;
  z-index: 105;
}
.topImg {
  width: 100%;
  height: 400px;
  position: absolute;
  left: 0;
  top: 0;
  overflow: hidden;
  z-index: 100;
}
.topBackgroundImg {
  width: 100%;
}

.knowMore {
  width: 203px;
  height: 46px;
  line-height: 46px;
  color: white;
  padding-right: 26px;
  box-sizing: border-box;
  background-color: #1e2233;
  position: absolute;
  top: 337px;
  left: 73px;
  cursor: pointer;
}
.text {
  margin-left: 20px;
}
.boxRightItem2Img1 {
  width: 24px;
  height: 24px;
  position: absolute;
  right: 24px;
  top: 11px;
}
.downStyle {
  animation: change2 0.5s ease-in forwards;
}

/* 动画样式 */
@keyframes change1 {
  from {
    opacity: 0;
    height: 400px;
  }
  to {
    opacity: 1;
    height: 505px;
  }
}

@keyframes change2 {
  from {
    top: 327px;
  }
  to {
    top: 367px;
  }
}

@keyframes change3 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
</style>
