<template>
  <div id="card">
    <div class="vedioPlay">
      <video class="backVideo" ref="video" :poster="videoItem.poster" :src="videoItem.backVideoUrl" @mouseenter="setControl" @mouseleave="removeControl"></video>
    </div>
    <div class="bottomTip">{{ videoItem.title }}</div>
  </div>
</template>

<script>
export default {
  name: 'VedioCard',
  data() {
    return {};
  },

  props: {
    videoItem: Object,
  },

  mounted() {},

  methods: {
    setControl(){
      this.$refs.video.setAttribute('controls','controls')
    },
    removeControl(){
      this.$refs.video.removeAttribute('controls')

    }
  },
};
</script>

<style scoped>
#card {
  width: 240px;
  height: 171px;
  position: relative;
  margin: 0 15px;
}

.vedioPlay {
  width: 100%;
  height: calc(100% - 35px);
}
.backVideo {
  width: 100%;
  height: 100%;
  outline: none;
}
.startImg {
  width: 40px;
  height: 40px;
  position: absolute;
  left: 124px;
  top: 67px;
}
.bottomTip {
  width: 100%;
  height: 35px;
  line-height:35px;
  background: linear-gradient(-45deg, #57BBFF, #2C86FF);
  text-align: left;
  text-indent: 15px;
  font-family: Source Han Sans CN;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  letter-spacing: 0px;
  color: #ffffff;
  cursor: pointer;
}
</style>