<template>
  <div id="example">
    <ProjectList />
    <div class="section">
      <div class="title">精华模板</div>
      <div class="exampleList">
        <PageCard v-for="(item, index) in pageList" :key="index" :cardItem="item" />
      </div>
    </div>
    <div class="section second">
      <div class="title">操作视频</div>
      <div class="exampleList">
        <vedioCard v-for="(item, index) in vedioList" :key="index" :videoItem="item"></vedioCard>
      </div>
    </div>
  </div>
</template>

<script>
import ProjectList from './projectList.vue'
import PageCard from './card.vue'
import VedioCard from './vedioCard.vue'

export default {
  name: 'VdragWelcomExample',
  components: { ProjectList, PageCard, VedioCard },
  data () {
    return {
      itemFlag: '0',
      pageList: [
        {
          imagUrl: require('../assets/image/dataView.png'),
          title: '数据可视化大屏',
          jumpTo: '/view/previewTemp?preview=1&tempId=14'
        },
        {
          imagUrl: require('../assets/image/wisdom.png'),
          title: '智慧园区',
          jumpTo: '/view/previewTemp?preview=1&tempId=16'
        },
        {
          imagUrl: require('../assets/image/enviroment.png'),
          title: '智慧环保可视化平台',
          jumpTo: '/view/previewTemp?preview=1&tempId=12'
        },
        {
          imagUrl: require('../assets/image/num.png'),
          title: '数字孪生管理平台',
          jumpTo: '/view/previewTemp?preview=1&tempId=3'
        },
        {
          imagUrl: require('../assets/image/travel.png'),
          title: '旅游数据管理平台',
          jumpTo: '/view/previewTemp?preview=1&tempId=10'
        }
      ],
      vedioList: [
        {
          title: '1分钟带你了解编辑器',
          backVideoUrl: require('../assets/video/fast.mp4'),
          poster: require('../assets/video/fast.png')
        },
        {
          title: '后台管理可以做什么',
          backVideoUrl: require('../assets/video/manage.mp4'),
          poster: require('../assets/video/manage.png')
        },
        {
          title: '创建团队、创建项目',
          backVideoUrl: require('../assets/video/create.mp4'),
          poster: require('../assets/video/create.png')
        },
        {
          title: '表格的使用方法',
          backVideoUrl: require('../assets/video/table.mp4'),
          poster: require('../assets/video/table.png')
        },
        {
          title: '数字地球的使用方法',
          backVideoUrl: require('../assets/video/earth.mp4'),
          poster: require('../assets/video/earth.png')
        }
      ]
    }
  },

  mounted () { },

  methods: {
    enter (e) {
      this.itemFlag = e.target.dataset.item
      console.log(this.itemFlag)
    },
    leave () {
      this.itemFlag = '0'
      console.log(this.itemFlag)
    }
  }
}
</script>

<style scoped>
#example {
  width: 100%;
  background: url("../assets/image/图7.png");
  background-size: 100% 100%;
  position: relative;
  overflow: hidden;
  padding-bottom: 120px;
}

.section {
  overflow: hidden;
  position: relative;
}
.title {
  font-size: 24px;
  font-weight: 700;
  letter-spacing: 0px;
  line-height: 34.75px;
  color: rgba(250, 250, 250, 1);
  text-align: center;
  margin: 80px 0 64px 0;
}
.exampleList {
  width: 100%;
  margin: 0 auto;
  margin-bottom: 0;
  display: flex;
  justify-content: center;
  overflow: hidden;
  flex-wrap: wrap;
}
</style>
