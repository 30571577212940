<template>
  <div id="footer">
    <div class="top">
      <div class="section first">
        <div class="title">友情链接</div>
        <a
          data-num="1"
          @mouseenter="enter($event)"
          @mouseleave="leave($event)"
          :class="{ item: true, current: itemFlag === '1' }"
          href="http://www.geovis.com.cn/"
          target="_blank"
          >中科星图股份有限公司</a
        >
        <a
          data-num="2"
          @mouseenter="enter($event)"
          @mouseleave="leave($event)"
          :class="{ item: true, current: itemFlag === '2' }"
          target="_blank"
          href="https://scada.vdrag.cn/#/"
          >vDrag.SCADA</a
        >
        <a
          data-num="13"
          @mouseenter="enter($event)"
          @mouseleave="leave($event)"
          :class="{ item: true, current: itemFlag === '13' }"
          target="_blank"
          href="https://h5.vdrag.cn/#/"
          >vDrag.H5</a>
      </div>
      <div class="section second">
        <div class="title">快速入口</div>
        <a
          data-num="4"
          href='/admin/login.html'
          @mouseenter="enter($event)"
          @mouseleave="leave($event)"
          :class="{ item: true, current: itemFlag === '4' }"
          target="_blank"
          >注册</a
        >
        <a
          data-num="5"
          href='/admin/login.html'
          @mouseenter="enter($event)"
          @mouseleave="leave($event)"
          :class="{ item: true, current: itemFlag === '5' }"
          target="_blank"
          >登录</a
        >
        <!-- <a
          data-num="6"
          href='/admin/index.html#/toai'
          @mouseenter="enter($event)"
          @mouseleave="leave($event)"
          :class="{ item: true, current: itemFlag === '6' }"
          target="_blank"
          >技术圈</a
        > -->
        <a
          data-num="7"
          href='https://www.yuque.com/hututubuhutu-x8mb5/xcetyh/de7x6m'
          @mouseenter="enter($event)"
          @mouseleave="leave($event)"
          :class="{ item: true, current: itemFlag === '7' }"
          target="_blank"
          >用户手册</a
        >
      </div>

      <div class="section third">
        <div class="title">联系我们</div>
        <a
          :class="{ item: true }"
          target="_blank"
          >15771751961&nbsp;(王经理)</a
        >
        <a
          :class="{ item: true}"
          target="_blank"
          >18991379879&nbsp;(马经理)</a
        >
      </div>

      <div class="section fourth">
        <div class="title">联系地址</div>
        <a
          :class="{ item: true }"
          target="_blank"
          >陕西省西安市国家民用航天产业基地航拓路中段汇航广场B座7层</a
        >
      </div>

      <div class="section five">
        <div class="imgBox">
          <img class="imgItem" src="../assets/image/logo20231110/jishu.svg" alt="" />
          <img class="imgItem" src="../assets/image/logo20231110/guanzhu.svg" alt="" />
        </div>
      </div>
    </div>
    <div class="bottom">
      <p>版权所有©2023  中科星图金能（南京）科技有限公司</p>
      <a href="http://www.beian.gov.cn/portal/registerSystemInfo?recordimgItem=61019402000065" target="_blank">
      <img src="../assets/image/police.png" style="float:left;"/>陕公网安备 61019402000065号</a>
      <a href="https://beian.miit.gov.cn/" target="_blank">苏ICP备2023044545号-2</a>
    </div>
  </div>
</template>

<script>
export default {
  name: 'VdragWelcomFooter',

  data () {
    return {
      itemFlag: '0',
      bottomAdress: window.VDrag_CONFIG,
      showBottom: false
    }
  },
  mounted () {
    this.showBottom = window.location.href.indexOf('vdrag.cn') > -1
  },
  methods: {
    enter (e) {
      this.itemFlag = e.target.dataset.num
    },
    leave () {
      this.itemFlag = '0'
    }
  }
}
</script>

<style scoped>
* {
  list-style: none;
  margin: 0;
  padding: 0;
}
a {
  text-decoration: none;
  display: block;
  color: white;
}
#footer {
  width: 100%;
  height: 376px;
  background-color: #272727;
  position: relative;
}

.section {
  color: white;
  /* position: absolute; */
  /* top: 100px; */
  /* width: 200px; */
  height: 154px;
}
.top{
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 1200px;
  height: 154px;
  margin: auto;
  padding-top: 100px;
}
.first {
  /* left: 281px; */
}
.second {
  /* left: 560px; */
}
.third{
  /* left: 750px; */
  width: 156px;
}
.fourth{
  /* left: 1100px; */
  width: 276px;
}
.title {
  width: 96px;
  height: 31px;
  text-align: left;
  font-family: Microsoft YaHei;
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  line-height: 31px;
  letter-spacing: 0px;
  color: #fafafa;
}
.item {
  width: 100%;
  height: 21px;
  font-family: Microsoft YaHei;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  line-height: 21px;
  letter-spacing: 0px;
  color: #ddd;
  text-align: left;
  margin-top: 12px;
}
.current {
  color: #409eff;
}

.imgItem {
  width: 130px;
  height: 150px;
  margin-left: 20px;
}

.imgBox{
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }

.bottom{
  position: absolute;
  bottom: 30px;
  left: 50%;
  display: inline-block;
  transform: translateX(-50%);
  color: white!important;
  font-size: 12px;
}
.bottom a {
  margin-right: 10px;
  display: inline-block;
}
.bottom p {
  margin-bottom: 5px;
}
</style>
