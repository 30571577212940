<template>
  <div id="card">
    <a :href="cardItem.jumpTo" target="_blank">
      <div class="topBox">
        <img class="topImg" :src="cardItem.imagUrl" alt="" />
      </div>
      <div class="title">{{ cardItem.title }}
        <span>查看</span>
      </div>
    </a>
  </div>
</template>

<script>
export default {
  name: 'PageCard',
  data() {
    return {};
  },
  props: {
    cardItem: {
      type: Object,
    },
  },
  mounted() {},

  methods: {},
};
</script>

<style scoped>
#card {
  width: 240px;
  height: 170px;
  background: url("../assets/image/空白卡片.png");
  background-size: 108%;
  background-position: -10px 0;
  position: relative;
  cursor: pointer;
  margin: 0 15px;
}
.topBox {
  width: 240px;
  height: 135px;
  position: relative;
}
.topImg {
  width: 240px;
  height: 135px;
  border-radius: 4px 4px 0px 0px;
}
.isOpen {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.685);
}
.isOpenTitle {
  width: 150px;
  text-align: center;
  font-family: Source Han Sans CN;
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  letter-spacing: 0px;
  color: #ffffff;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.title {
  width: 100%;
  height: 35px;
  padding-left: 15px ;
  box-sizing: border-box;
  line-height: 35px;
  text-align: left;
  font-family: Source Han Sans CN;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  letter-spacing: 0px;
  color: #070707;
  position: absolute;
  top: 135px;
  cursor: pointer;
}
.title>span{
  color: rgba(64, 158, 255, 1);
  border-bottom: 1px solid rgba(64, 158, 255, 1);
  position: absolute;
  right: 20px;
  width: 30px;
  height: 28px;
  text-align: center;
}

.authorImg {
  width: 24px;
  height: 24px;
  position: absolute;
  left: 15px;
  bottom: 9px;
}
.authorName {
  width: 42px;
  height: 14px;
  line-height: 14px;
  font-family: Source Han Sans CN;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  letter-spacing: 0px;
  color: #687282;
  position: absolute;
  left: 45px;
  bottom: 12px;
  cursor: default;
}
</style>